import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import posthog from 'posthog-js';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/gallery',
    children: [
      {
        path: 'affiliate/:id',
        component: () => import('./pages/affiliate/[id].vue'),
      },
      {
        path: 'editor/:template+',
        component: () => import('./pages/editor/[...template].vue'),
      },
      {
        path: 'gallery',
        component: () => import('./pages/gallery/index.vue'),
      },
      {
        path: 'invite/:id',
        component: () => import('./pages/invite/[id].vue'),
      },
      {
        path: 'onboarding/:path*',
        component: () => import('./pages/onboarding.vue'),
      },
      {
        path: 'settings/:path*',
        component: () => import('./pages/settings/[...path].vue'),
      },
      {
        path: 'hub',
        component: () => import('./pages/hub/index.vue'),
      },
      {
        path: 'drafts/:path*',
        component: () => import('./pages/hub/drafts/[...path].vue'),
      },
      {
        path: 'shared/:id',
        component: () => import('./pages/shared/[...id].vue'),
      },
    ],
  },
  { path: '/test', component: () => import('./pages/test.vue') },
  { path: '/forbidden', component: () => import('./pages/forbidden.vue') },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to, from, failure) => {
  if (!failure) {
    nextTick(() => {
      posthog.capture('$pageview', { path: to.fullPath });
    });
  }
});
